import { BoxProps, FormControl, FormLabel } from "@chakra-ui/react";
import { ResponseType, WorkflowField } from "../../types/workflow";
import { LongTextFormField } from "./LongTextFormField";
import NumberFormField from "./NumberFormField";
import PropertyFormField from "./PropertyFormField";
import RadioFormField from "./RadioFormField";
import { ShortTextFormField } from "./ShortTextFormField";
import { LinearScaleFormField } from "./LinearScaleFormField";
import { CheckboxFormField } from "./CheckboxFormField";

interface FormFieldProps extends BoxProps {
  field: WorkflowField;
  questionNum: number;
  handleInputChange: (key: string, value: string) => void;
}

const FormField = ({
  field,
  questionNum,
  handleInputChange,
  ...rest
}: FormFieldProps) => {
  const fieldTypeMap: Record<string, React.ReactNode> = {
    [ResponseType.RADIO]: (
      <RadioFormField field={field} handleInputChange={handleInputChange} />
    ),
    [ResponseType.LONG_TEXT]: (
      <LongTextFormField field={field} handleInputChange={handleInputChange} />
    ),
    [ResponseType.SHORT_TEXT]: (
      <ShortTextFormField field={field} handleInputChange={handleInputChange} />
    ),
    [ResponseType.NUMBER]: (
      <NumberFormField field={field} handleInputChange={handleInputChange} />
    ),
    [ResponseType.PROPERTY]: (
      <PropertyFormField field={field} handleInputChange={handleInputChange} />
    ),
    [ResponseType.CHECKBOX]: (
      <CheckboxFormField field={field} handleInputChange={handleInputChange} />
    ),
    [ResponseType.LINEAR_SCALE]: (
      <LinearScaleFormField field={field} handleInputChange={handleInputChange} />
    ),
  };

  return (
    <FormControl isRequired={field.required} {...rest}>
      <FormLabel>
        {questionNum}. {field.question}
      </FormLabel>
      {fieldTypeMap[field.responseType]}
    </FormControl>
  );
};

export default FormField;
