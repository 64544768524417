import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Button,
  Select,
  useToast,
  HStack,
  Text,
  Divider,
  Heading,
  Stack,
  Spacer,
  IconButton,
  Center,
  TableCaption,
  CircularProgress,
} from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate, useParams } from "react-router-dom";
import { Property } from "../../types/tag";
import { DocumentDto, PropertyData } from "../../types/patient";
import { getDocById, getDocUrlById, parseDocument } from "../../api/document";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { FaChevronLeft, FaEye, FaPlus, FaTrash } from "react-icons/fa";
import { formatEnumValue } from "../../utils/strings";
import { PatientMetricDto } from "../../types/workflow";
import { createAllPatientMetrics } from "../../api/patientMetric";

interface TableRow {
  property: string;
  category?: string;
  value: string;
  unit: string;
}

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";

const ReportPage = () => {
  const navigate = useNavigate();
  const [doc, setDoc] = useState<DocumentDto | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Partial<Record<Property, PropertyData>>>({});
  const [reportUrl, setReportUrl] = useState<string>("");
  const [progress, setProgress] = useState(0);
  const [timer, setTimer] = useState();
  const [numPages, setNumPages] = useState<number>();
  const [rows, setRows] = useState<TableRow[]>([]);
  const toast = useToast();
  const { docId } = useParams();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  });

  useEffect(() => {
    if (docId) {
      fetchData();
    }
  }, [docId]);

  const startProgress = (timeinSeconds: number, increments: number) => {
    if (!timer) {
      const numberOfIncrements = 100 / increments;
      const gap = (timeinSeconds * 1000) / numberOfIncrements;
      let progressBar = progress;
      const interval = setInterval(() => {
        progressBar += increments;
        setProgress(progressBar);
        if (progressBar >= 100) {
          clearInterval(interval);
          setTimer(undefined);
        }
      }, gap);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setTimer(interval as any);
    }
  };

  const fetchData = async () => {
    if (!docId) return;
    try {
      startProgress(10, 4);
      const docResp = await getDocById(docId);
      if (docResp.data) {
        setDoc(docResp.data);
      }

      const docUrlResp = await getDocUrlById(docId);
      if (docUrlResp.data) {
        console.log("Document URL:", docUrlResp.data);
        setReportUrl(docUrlResp.data);
      }

      const dataResp = await parseDocument(docId);
      setData(dataResp.data);
      setRows(
        Object.entries(dataResp.data).map(([key, details]) => ({
          property: key,
          category: details.category ?? "",
          value: details.value?.toString() ?? "",
          unit: details.unit ?? "",
        }))
      );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddRow = () => {
    setRows([...rows, { property: "", category: "", value: "", unit: "" }]);
  };

  const handleSave = async () => {
    console.log("Saving data:", rows);
    const currentDate = new Date();
    const patientMetricDtos: PatientMetricDto[] = rows.map((row) => ({
      id: "", // This should be empty string for new data
      patientId: doc?.patientId!, // This should be dynamic based on your application context
      propertyId: row.property,
      value: parseFloat(row.value),
      timestamp: currentDate.getTime().toString(),
      unit: row.unit,
      category: row.category,
    }));
    try {
      await createAllPatientMetrics(patientMetricDtos);
      toast({
        title: "Success",
        description: "Data saved successfully!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate(-1);
    } catch (error) {
      console.error("Failed to save data:", error);
    }
  };
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  return (
    <Flex>
      <Box flex={1}>
        <Flex justifyContent={"space-between"} m={2}>
          <HStack
            cursor={"pointer"}
            alignItems={"center"}
            color={"gray"}
            onClick={() => navigate(-1)}
          >
            <FaChevronLeft />
            <Text>Back</Text>
          </HStack>
          <Button
            leftIcon={<FaEye />}
            variant="ghost"
            as="a"
            href={`${API_URL}/docs/url/${docId}`}
            target="_blank"
            rel="noreferrer"
          >
            View Document
          </Button>
        </Flex>
        <Box overflow={"auto"} h={"90vh"} m={2}>
          <Document
            file={`${API_URL}/docs/url/${docId}`}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={500}
              />
            ))}
          </Document>
        </Box>
      </Box>
      <Box flex={2} m={4}>
        <Heading>
          <Text fontSize="xxl">Data Entry</Text>
          <Divider mb={4} />
        </Heading>

        <Stack h="90%">
          <Box maxH={"70vh"} overflow={"auto"}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th width="35%">Property</Th>
                  <Th width="22.5%">Category</Th>
                  <Th width="20%">Value</Th>
                  <Th width="20%">Unit</Th>
                  <Th width="2.5%">Action</Th>
                </Tr>
              </Thead>
              {isLoading ? (
                <TableCaption height="70" w="100%">
                  <Center>
                    <CircularProgress
                      value={progress}
                      isIndeterminate={progress >= 100 || !timer}
                    />
                  </Center>
                </TableCaption>
              ) : (
                <Tbody>
                  {rows.map((row, index) => (
                    <Tr key={index}>
                      <Td>
                        <Select
                          value={row.property}
                          onChange={(e) => {
                            const updatedRows = [...rows];
                            updatedRows[index].property = e.target.value;
                            setRows(updatedRows);
                          }}
                        >
                          {Object.keys(Property)
                            .sort()
                            .map((key: string) => (
                              <option
                                value={Property[key as keyof typeof Property]}
                              >
                                {formatEnumValue(
                                  Property[key as keyof typeof Property]
                                )}
                              </option>
                            ))}
                        </Select>
                      </Td>
                      <Td>
                        <Input
                          value={row.category}
                          onChange={(e) => {
                            const updatedRows = [...rows];
                            updatedRows[index].category = e.target.value;
                            setRows(updatedRows);
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          type="number"
                          value={row.value}
                          onChange={(e) => {
                            const updatedRows = [...rows];
                            updatedRows[index].value = e.target.value;
                            setRows(updatedRows);
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          value={row.unit}
                          onChange={(e) => {
                            const updatedRows = [...rows];
                            updatedRows[index].unit = e.target.value;
                            setRows(updatedRows);
                          }}
                        />
                      </Td>
                      <Td>
                        <IconButton
                          aria-label="delete"
                          icon={<FaTrash />}
                          variant={"ghost"}
                          onClick={() => {
                            const updatedRows = [...rows];
                            updatedRows.splice(index, 1);
                            setRows(updatedRows);
                          }}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </Box>
          <Button
            leftIcon={<FaPlus></FaPlus>}
            onClick={handleAddRow}
            variant={"ghost"}
            isDisabled={isLoading}
          >
            Add Row
          </Button>
          <Spacer />
          <Button
            colorScheme="blue"
            onClick={handleSave}
            isDisabled={isLoading}
          >
            Save Data
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};

export default ReportPage;
