import axios from "axios";
import {
  CreateWorkflowDto,
  RespondWorkflowDto,
  WorkflowDto,
} from "../types/workflow";
import axiosAuth from "./axiosAuth";

// Using React environment variable for API URL
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";

async function createWorkflow(workflowDto: CreateWorkflowDto) {
  const res = await axiosAuth.post<WorkflowDto>(
    `${API_URL}/form`,
    workflowDto
  );
  return res.data;
}

async function updateWorkflow(workflowDto: WorkflowDto) {
  const res = await axiosAuth.put<WorkflowDto>(
    `${API_URL}/form`,
    workflowDto
  );
  return res.data;
}

async function getWorkflowList() {
  const res = await axiosAuth.get<WorkflowDto[]>(`${API_URL}/form/list`);
  return res.data;
}

async function getWorkflowById(workflowId: string) {
  const res = await axios.get<WorkflowDto>(
    `${API_URL}/form?formId=${workflowId}`
  );
  return res.data;
}

async function deprecateWorkflowById(workflowId: string) {
  const res = await axiosAuth.put<WorkflowDto>(
    `${API_URL}/form/deprecate/${workflowId}`
  );
  return res.data;
}

async function getWorkflowsByPatientId(patientId: string) {
  const res = await axiosAuth.get<WorkflowDto[]>(
    `${API_URL}/form/patient/${patientId}`
  );
  return res.data;
}

async function sendFlow({
  patientId,
  workflowId,
}: {
  patientId: string;
  workflowId: string;
}) {
  try {
    const res = await axiosAuth.post(`${API_URL}/form/send`, {
      patientId,
      workflowId,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

async function respondToWorkflow(
  req: RespondWorkflowDto
): Promise<RespondWorkflowDto> {
  try {
    const res = await axios.post<RespondWorkflowDto>(
      `${API_URL}/form/response`,
      req
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}

export {
  createWorkflow,
  deprecateWorkflowById,
  getWorkflowById,
  getWorkflowList,
  getWorkflowsByPatientId,
  respondToWorkflow,
  sendFlow,
  updateWorkflow,
};
