import { Button, HStack, Text } from "@chakra-ui/react";
import { WorkflowField } from "../../types/workflow";
import { theme } from "../../constants";
import { useState } from "react";

interface LinearScaleFormFieldProps {
  field: WorkflowField;
  handleInputChange: (key: string, value: string) => void;
}

export const LinearScaleFormField = ({
  field,
  handleInputChange,
}: LinearScaleFormFieldProps) => {
  const [selectedValue, setSelectedValue] = useState(-1);

  const handleClick = (index: number) => {
    setSelectedValue(index);
    handleInputChange(field.key, index.toString());
  };

  return (
    <HStack>
      {[...Array(11)].map((_, index) => (
        <Button
          key={index}
          width="30px"
          height="30px"
          bg={selectedValue === index ? `${theme}.700` : "white"}
          color={selectedValue === index ? "white" : `${theme}.800`}
          border="1px solid"
          borderColor={selectedValue === index ? `${theme}.700` : "gray.300"}
          borderRadius="md"
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => handleClick(index)}
          _hover={{ bg: selectedValue === index ? `${theme}.700` : "gray.100" }}
          _active={{ bg: selectedValue === index ? `${theme}.700` : "gray.200" }}
        >
          <Text fontSize={{ base: "sm", '2xl': 'md' }}>{index}</Text>
        </Button>
      ))}
    </HStack>
  );
};
