import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  Center,
  Container,
  Flex,
  HStack,
  Heading,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaDownload, FaFacebookMessenger, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Frequency, RRule } from "rrule";
import { getAllPatients } from "../../api/patients";
import { getWorkflowDataList } from "../../api/workflowData";
import {
  deprecateWorkflowById,
  getWorkflowById,
  updateWorkflow,
} from "../../api/workflows";
import DeprecateFlowModal from "../../components/Flows/DeprecateModal";
import Header from "../../components/Head";
import { messagingApps, ROUTES, theme } from "../../constants";
import { dayOptions, DEFAULT_SELECTED_HOUR, formatHourStr, frequencyOptions, hours } from "../../constants/time";
import { PatientDto } from "../../types/patient";
import {
  ProviderType,
  ResponseType,
  WorkflowDataResp,
  WorkflowDto,
} from "../../types/workflow";
import {
  exportToCSV,
} from "../../utils";
import { AiOutlineFileText, AiOutlineTeam } from "react-icons/ai";
import FormField from "../../components/Form/FormField";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList, AutoCompleteTag } from "@choc-ui/chakra-autocomplete";

const ViewFlowPage = () => {
  const { flowId } = useParams();
  const {
    isOpen: isDeprecateModalOpen,
    onOpen: openDeprecateModal,
    onClose: onCloseDeprecateFlowModal,
  } = useDisclosure();
  const [responses, setResponses] = useState<WorkflowDataResp[]>([]);
  const [workflow, setWorkflow] = useState<WorkflowDto>();
  const toast = useToast();
  const navigate = useNavigate();
  const [isRefreshed, setIsRefreshed] = useState(false);

  // variables needed for sending workflow
  const [communicationChannel, setCommunicationChannel] =
    useState<ProviderType>(messagingApps[0]);
    const [frequency, setFrequency] = useState<Frequency>(Frequency.DAILY);
  const [selectedDay, setSelectedDay] = useState<number>(RRule.MO.weekday);
  const [selectedHour, setSelectedHour] = useState<number>(
    DEFAULT_SELECTED_HOUR
  );
  const [selectedPatientIds, setSelectedPatientIds] = useState<string[]>([]);
  const [patients, setPatients] = useState<PatientDto[]>([]);

  // on top of the normal columns, we have 3 additional columns (#, patient name and date recorded)
  const NUM_ADDITIONAL_COLS = 3;

  useEffect(() => {
    async function getPatients() {
      const patientResp = await getAllPatients();
      setPatients(patientResp.data.data ?? []);
    }

    getPatients();

    if (isRefreshed) {
      return;
    }

    async function getPageData() {
      if (!flowId) {
        return;
      }

      const workflow = await getWorkflowById(flowId);
      if (!workflow) {
        return;
      }

      setWorkflow(workflow);

      const responses = await getWorkflowDataList({
        workflowIds: [flowId],
      });
      if (!responses) {
        return;
      }

      setResponses(responses);
    }

    getPageData();

    setIsRefreshed(true);
  }, [isRefreshed]);

  if (!flowId) {
    navigate(ROUTES.NOT_FOUND);
    return <></>;
  }

  if (!workflow) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  const deprecateFlow = async () => {
    const resp = await deprecateWorkflowById(flowId);
    if (resp) {
      setIsRefreshed(false);
      toast({
        title: "Successfully deprecated flow",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate(ROUTES.FLOWS_LIST);
    } else {
      toast({
        title: "Failed to deprecate flow",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSave = () => {
    const now = new Date();

    // Create a new Date object representing the next occurrence of the selected day and hour
    const nextOccurrence = new Date(now);
    nextOccurrence.setDate(
      now.getDate() + ((7 + selectedDay - now.getDay()) % 7)
    );
    nextOccurrence.setHours(selectedHour, 0, 0);

    const recurrenceRule = new RRule({
      freq: frequency,
      wkst: nextOccurrence.getUTCDay(),
      byhour: nextOccurrence.getUTCHours(),
      byminute: nextOccurrence.getUTCMinutes(),
      bysecond: nextOccurrence.getUTCSeconds(),
    });

    const workflowDto: WorkflowDto = {
      ...workflow,
      channel: communicationChannel,
      patientIds: selectedPatientIds,
      schedule: recurrenceRule.toString(),
    };

    try {
      updateWorkflow(workflowDto);
      toast({
        title: "Success!",
        description: "Updated form sending schedule!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: "Bulk upload failed",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleExportToCSV = () => {
    const headers = [
      "#",
      "Client Name",
      ...(workflow?.fields.map((field) => field.label) ?? []),
      "Date Recorded",
    ];

    const dataRows = responses.map((response, idx) => [
      `${responses.length - idx}`,
      response.patientName,
      ...workflow?.fields.map(
        (field, fieldIdx) => response.rawData[`q_${fieldIdx + 1}`] ?? ""
      ),
      new Date(response.createdAt).toLocaleDateString("en-GB"),
    ]);

    exportToCSV(dataRows, headers, `${workflow?.name}_export` ?? "export_data");
  };

  return (
    <>
      <Container maxW="9xl">
        <Header description="View flow page" />

        <DeprecateFlowModal
          isOpen={isDeprecateModalOpen}
          onClose={onCloseDeprecateFlowModal}
          onConfirmDeprecate={deprecateFlow}
        />

        <VStack align={"start"} spacing={8}>
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink fontWeight={"light"} href={ROUTES.FLOWS_LIST}>Forms</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <Text>{workflow.name}</Text>
            </BreadcrumbItem>
          </Breadcrumb>
          
          <VStack w="full" spacing={2} alignItems={"flex-start"}>
            <HStack w="full">
              <Box w="min" bg={`gray.100`} borderRadius={"md"} p={1}>
                <AiOutlineFileText size={32} color="gray" />
              </Box>
              <Heading fontSize={{ base: "3xl", "2xl": "4xl" }}>
                {workflow.name}
              </Heading>
              <Spacer />
              <Button onClick={openDeprecateModal}>
                Deprecate Flow
              </Button>
            </HStack>
            <HStack>
            <Text color={`${theme}.800`} fontSize={{ base: "lg", "2xl": "xl" }}>
              {workflow.description}
            </Text>
            </HStack>
          </VStack>

          <Tabs isFitted size={{ base: "md", "2xl": "lg" }} w="full">
            <TabList>
              <Tab>Questions</Tab>
              <Tab>Responses</Tab>
              <Tab>Send Form</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Text size={{ base: "md", "2xl": "lg" }} mb={4}>
                  {workflow.message}
                </Text>
                <VStack spacing={3} w="full" align={"flex-start"}>
                  {workflow.fields.map((field, index) => {
                    return (
                      <Card w="full">
                        <CardBody>
                          <FormField
                            key={index}
                            questionNum={index + 1}
                            field={field}
                            handleInputChange={() => void 0}
                          />
                        </CardBody>
                      </Card>
                    );
                  })}
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack mt={2} spacing={6} w="full" align={"flex-start"}>
                  <Flex justifyContent={"space-between"} w="full">
                    <Heading fontWeight={"normal"} size={{ base: "md", "2xl": "lg" }}>
                      {responses.length} Responses
                    </Heading>

                    <Button
                      aria-label="Export to CSV"
                      leftIcon={<FaDownload />}
                      onClick={handleExportToCSV}
                    >
                      Export to CSV
                    </Button>
                  </Flex>
                  <Box border="2px solid" borderRadius={"md"} borderColor={"gray.200"} p={4} w="full">
                    <Table variant="unstyled" size={{ base: "md", "2xl": "lg" }} fontSize={{ base: "md", "2xl": "lg" }}>
                      <Thead>
                        <Tr borderBottom={"2px solid"} borderColor={"gray.200"}>
                          <Th>
                            <Text>#</Text>
                          </Th>
                          <Th>
                            <Text>Client Name</Text>
                          </Th>
                          {workflow?.fields.map((field) => {
                            return (
                              <Th
                                isNumeric={field.responseType === ResponseType.NUMBER}
                                key={field.key}
                              >
                                <Text>{field.label}</Text>
                              </Th>
                            );
                          })}
                          <Th>
                            <Text>date recorded</Text>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {responses.length > 0 &&
                          responses.map((response, idx) => {
                            return (
                              <Tr key={response.id}>
                                <Td>{idx + 1}</Td>
                                <Td cursor={"pointer"}>{response.patientName}</Td>
                                {workflow?.fields.map((field, fieldIdx) => {
                                  return (
                                    <Td>{response.rawData[`q_${fieldIdx + 1}`] ?? ""}</Td>
                                  );
                                })}
                                <Td>
                                  {new Date(response.createdAt).toLocaleDateString(
                                    "en-GB"
                                  )}
                                </Td>
                              </Tr>
                            );
                          })}
                        {responses.length === 0 && (
                          <Tr>
                            <Td
                              textAlign={"center"}
                              colSpan={
                                (workflow?.fields.length ?? 0) + NUM_ADDITIONAL_COLS
                              }
                            >
                              No responses yet
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </Box>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack spacing={8} align="flex-start">
                  <VStack spacing={4} align={"flex-start"}>
                    <Text 
                      color={`${theme}.800`} 
                      fontSize={{ base: "md", "2xl": "lg" }}
                      fontWeight={"medium"}
                    >
                      Send form to
                    </Text>
                    <Card w={"full"} p={3}>
                      <HStack spacing={4}>
                        <Box w="min" bg={`gray.100`} borderRadius={"md"} p={1}>
                          <AiOutlineTeam size={32} />
                        </Box>
                        <AutoComplete
                          multiple
                          rollNavigation
                          onChange={(vals) =>
                            setSelectedPatientIds(
                              patients
                                .filter((cl) => vals.includes(cl.name))
                                .map((cl) => cl.id)
                            )
                          }
                        >
                          <AutoCompleteInput
                            w={{ base: "xl", "2xl": "2xl" }}
                            placeholder="Select patients"
                            autoFocus
                            fontSize={{ base: "md", "2xl": "lg" }}
                          >
                            {({ tags }) =>
                              tags.map((tag, tid) => (
                                <AutoCompleteTag
                                  key={tid}
                                  label={tag.label}
                                  onRemove={tag.onRemove}
                                />
                              ))
                            }
                          </AutoCompleteInput>
                          <AutoCompleteList>
                            {patients.length > 0 &&
                              patients.map((patient, idx) => (
                                <AutoCompleteItem
                                  key={`option-${idx}`}
                                  value={patient.name}
                                >
                                  {patient.name}
                                </AutoCompleteItem>
                              ))}
                          </AutoCompleteList>
                        </AutoComplete>
                      </HStack>
                    </Card>
                  </VStack>
                  
                  <VStack align={"flex-start"}>
                    <Text 
                      color={`${theme}.800`} 
                      fontSize={{ base: "md", "2xl": "lg" }}
                      fontWeight={"medium"}
                    >
                      Via
                    </Text>
                    <RadioGroup
                      onChange={(value) =>
                        setCommunicationChannel(value as ProviderType)
                      }
                      value={communicationChannel}
                    >
                      <VStack alignItems="flex-start" spacing={3}>
                        <Radio
                          key={ProviderType.WHATSAPP}
                          value={ProviderType.WHATSAPP}
                        >
                          <HStack>
                            <Text color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>Whatsapp</Text>
                            <FaWhatsapp />
                          </HStack>
                        </Radio>
                        <Tooltip
                          label="Contact Speedback support to activate this channel"
                          shouldWrapChildren
                        >
                          <Radio
                            isDisabled
                            key={ProviderType.FB_MESSENGER}
                            value={ProviderType.FB_MESSENGER}
                          >
                            <HStack>
                              <Text color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>Facebook Messenger</Text>
                              <FaFacebookMessenger />
                            </HStack>
                          </Radio>
                        </Tooltip>
                        <Tooltip
                          label="Contact Speedback support to activate this channel"
                          shouldWrapChildren
                        >
                          <Radio
                            isDisabled
                            key={ProviderType.TELEGRAM}
                            value={ProviderType.TELEGRAM}
                          >
                            <HStack>
                              <Text color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>Telegram</Text>
                              <FaTelegramPlane />
                            </HStack>
                          </Radio>
                        </Tooltip>
                        <Text color={`${theme}.800`} fontSize={{ base: "sm", "2xl": "md" }} fontWeight={"light"}>Notification charges apply</Text>
                      </VStack>
                    </RadioGroup>
                  </VStack>

                  <VStack align={"flex-start"}>
                    <Text 
                      color={`${theme}.800`} 
                      fontSize={{ base: "md", "2xl": "lg" }}
                      fontWeight={"medium"}
                      mb={2}
                    >
                      Schedule (Following timezone: Asia/Singapore, GMT+8)
                    </Text>
                    <HStack spacing={20} alignItems={"center"}>
                      <Text width={"100px"} color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>Run every:</Text>
                      <Select
                        width={"300px"}
                        value={frequency}
                        defaultValue={RRule.DAILY}
                        onChange={(e) => setFrequency(parseInt(e.target.value))}
                        color={`${theme}.800`} 
                        fontSize={{ base: "md", "2xl": "lg" }} 
                        fontWeight={"light"}
                      >
                        {frequencyOptions.map((freq) => (
                          <option key={freq.value} value={freq.value}>
                            {freq.name}
                          </option>
                        ))}
                      </Select>
                    </HStack>
                    {frequency === Frequency.WEEKLY && (
                      <HStack spacing={20} alignItems={"flex-start"}>
                        <Text width={"100px"} color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>On:</Text>
                        <Select
                          width={"300px"}
                          value={selectedDay}
                          defaultValue={RRule.MO.weekday}
                          onChange={(e) => setSelectedDay(parseInt(e.target.value))}
                          color={`${theme}.800`} 
                          fontSize={{ base: "md", "2xl": "lg" }} 
                          fontWeight={"light"}
                        >
                          {dayOptions.map((day) => (
                            <option key={day.value.weekday} value={day.value.weekday}>
                              {day.name}
                            </option>
                          ))}
                        </Select>
                      </HStack>
                    )}
                    <HStack spacing={20} alignItems={"flex-start"}>
                      <Text width={"100px"} color={`${theme}.800`} fontSize={{ base: "md", "2xl": "lg" }}>At:</Text>
                      <Select
                        width={"300px"}
                        value={selectedHour}
                        defaultValue={DEFAULT_SELECTED_HOUR}
                        onChange={(e) => setSelectedHour(parseInt(e.target.value))}
                        color={`${theme}.800`} 
                        fontSize={{ base: "md", "2xl": "lg" }} 
                        fontWeight={"light"}
                      >
                        {hours.map((hour) => (
                          <option key={hour} value={hour}>
                            {formatHourStr(hour)}
                          </option>
                        ))}
                      </Select>
                    </HStack>
                  </VStack>

                  <Button
                    onClick={handleSave}
                    size={{ base: "md", "2xl": "lg" }}
                    w={"xl"}
                  >
                    Save and Continue
                  </Button>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Container>
    </>
  );
};

export default ViewFlowPage;
