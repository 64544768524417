import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Flex,
  FormControl,
  HStack,
  Heading,
  IconButton,
  Input,
  Select,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { deleteTag, getTagById, updateTag } from "../../api/tag";
import { ROUTES, theme } from "../../constants";
import { ConditionDto, Operation, PatientTagDto, Property, TagDto } from "../../types/tag";
import { formatEnumValue } from "../../utils/strings";
import Header from "../../components/Head";
import { AiOutlineQuestionCircle, AiOutlineTag } from "react-icons/ai";
import { PatientTagsTable } from "../../components/Tags/PatientTagsTable";
import TablePagination from "../../components/TablePagination";
import { getAllPatientTagsByTagId } from "../../api/patientTags";

export const ViewPatientTagsPage = () => {
  const navigate = useNavigate();
  const { tagId } = useParams();
  const toast = useToast();

  const [patientTags, setPatientTags] = useState<PatientTagDto[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalTags, setTotalTags] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Initial state for tag details
  const [tag, setTag] = useState<TagDto>({
    name: "",
    tagColor: "",
    description: "",
    removeWhenNotMet: false,
    tagRules: [
      {
        conditions: {
          type: "AND",
          conditions: [
            {
              property: "",
              operation: Operation.GT,
              value: "",
            } as ConditionDto,
          ],
        },
      },
    ],
  });

  // Handle input changes for non-nested fields
  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setTag((prevDetails) => ({
      ...prevDetails,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  useEffect(() => {
    if (tagId) {
      fetchTag(tagId);
    }
  }, [tagId]);

  useEffect(() => {
    fetchAllPatientTags();
  }, [currentPage, itemsPerPage]);

  const fetchTag = async (tagId: string) => {
    const resp = await getTagById(tagId);
    if (resp.data) {
      setTag(resp.data);
    }
  };

  const fetchAllPatientTags = async () => {
    const resp = await getAllPatientTagsByTagId(
      currentPage,
      itemsPerPage,
      tagId!
    );
    if (resp.data) {
      if (resp.data.data) {
        setPatientTags(resp.data.data);
      }

      setTotalTags(resp.data.total);
    }
  };

  // Add a new condition to the tag logic
  const addCondition = () => {
    const newCondition = {
      property: "",
      operation: Operation.GT,
      value: "",
    };
    setTag((prevDetails) => ({
      ...prevDetails,
      tagRules: [
        {
          ...prevDetails.tagRules[0],
          conditions: {
            ...prevDetails.tagRules[0].conditions,
            conditions: [
              ...prevDetails.tagRules[0].conditions.conditions,
              newCondition,
            ],
          },
        },
      ],
    }));
  };

  // Handle input changes for dynamic conditions
  const handleConditionChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const newConditions = [...tag.tagRules[0].conditions.conditions];
    newConditions[index] = { ...newConditions[index], [field]: value };
    setTag((prevDetails) => ({
      ...prevDetails,
      tagRules: [
        {
          ...prevDetails.tagRules[0],
          conditions: {
            ...prevDetails.tagRules[0].conditions,
            conditions: newConditions,
          },
        },
      ],
    }));
  };

  // Remove a condition from the tag logic
  const removeCondition = (index: number) => {
    const newConditions = [...tag.tagRules[0].conditions.conditions];
    newConditions.splice(index, 1);
    setTag((prevDetails) => ({
      ...prevDetails,
      tagRules: [
        {
          ...prevDetails.tagRules[0],
          conditions: {
            ...prevDetails.tagRules[0].conditions,
            conditions: newConditions,
          },
        },
      ],
    }));
  };

  const handleSave = async () => {
    try {
      await updateTag(tag.id!, tag);
      navigate(ROUTES.TAGS);
    } catch (e) {
      toast({
        title: "Error",
        description: "An error occurred. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
  };

  const handleDelete = async (tagId: string) => {
    const resp = await deleteTag(tagId);
    if (resp.status === 200) {
      navigate(ROUTES.TAGS);
    } else {
      toast({
        title: "Error",
        description: "An error occurred. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Container maxW={"9xl"}>
        <Header description="Create Tag" />

        <VStack align={"start"} spacing={6} w="full">
          <Breadcrumb mb={2}>
            <BreadcrumbItem>
              <BreadcrumbLink fontWeight={"light"} href={ROUTES.TAGS}>Tags</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <Text>{tag.name}</Text>
            </BreadcrumbItem>
          </Breadcrumb>

          <VStack align={"start"} w="full">
            <HStack w="full">
              <Box w="min" bg={`gray.100`} borderRadius={"md"} py={1} px={2}>
                <AiOutlineTag size={32} color="gray" />
              </Box>
              <Heading
                fontSize={{ base: "3xl", "2xl": "4xl" }}
                textTransform={"uppercase"}
              >
                {tag.name}
              </Heading>
              <Spacer />
              <Button
                bg="red.500"
                _hover={{
                  bg: "red.700",
                }}
                size={{ base: "md", "2xl": "lg" }}
                fontSize={{ base: "md", "2xl": "lg" }}
                onClick={() => handleDelete(tag.id!)}
              >
                Delete tag
              </Button>
            </HStack>
            <Text 
              maxW="80%"
              fontSize={{ base: "lg", "2xl": "xl" }}
              fontWeight="normal"
              color={`${theme}.800`}
            >
              Created by {tag.createdBy}
            </Text>
          </VStack>

          <Divider />

          <Tabs w="full" isFitted size={{ base: "md", "2xl": "lg" }}>
            <TabList>
              <Tab>Manage</Tab>
              <Tab>Clients</Tab>
            </TabList>
            <TabPanels w="full">
              <TabPanel>
                <Flex justifyContent={"center"} w="full">
                  <VStack mt={32} spacing={4} align={"start"} w="70%">
                    <Heading
                      fontSize={{ base: "3xl", "2xl": "4xl" }}
                      color={`${theme}.700`}
                    >
                      Manage Tag Logic
                    </Heading>
                    <Card width="full" p={3}>
                      <HStack align={"start"} justify={"start"} w="full" spacing={4}>
                        <Box w="min" bg={`gray.100`} borderRadius={"md"} py={1} px={2}>
                          <AiOutlineTag size={24} />
                        </Box>
                        <VStack align={"start"} w="full" spacing={3}>
                          <HStack w="full" spacing={3}>
                            <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} fontWeight={"medium"}>
                              Add tag with name:
                            </Text>
                            <Input
                              placeholder="Tag Name"
                              name="name"
                              value={tag.name}
                              size={{ base: "sm", "2xl": "md" }}
                              fontSize={{ base: "md", "2xl": "lg" }}
                              color={`${theme}.800`}
                              w={"40%"}
                              onChange={handleChange}
                            />
                            <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} fontWeight={"medium"}>
                              and color:
                            </Text>
                            <FormControl w="20%" isRequired>
                              <Select
                                placeholder="Color"
                                name="tagColor"
                                value={tag.tagColor}
                                onChange={handleChange}
                                size={{ base: "sm", "2xl": "md" }}
                                fontSize={{ base: "md", "2xl": "lg" }}
                                color={`${theme}.800`}
                              >
                                <option value="grey">Grey</option>
                                <option value="red">Red</option>
                                <option value="orange">Orange</option>
                                <option value="green">Green</option>
                              </Select>
                            </FormControl>
                          </HStack>
                          <HStack w="full" spacing={3}>
                            <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} fontWeight={"medium"}>
                              and description:
                            </Text>
                            <Input
                              placeholder="Tag Description"
                              name="description"
                              value={tag.description}
                              size={{ base: "sm", "2xl": "md" }}
                              fontSize={{ base: "md", "2xl": "lg" }}
                              color={`${theme}.800`}
                              w={"80%"}
                              onChange={handleChange}
                            />
                          </HStack>
                        </VStack>
                      </HStack>
                    </Card>

                    <Text fontSize={{ base: "lg", "2xl": "xl" }} color={`${theme}.700`} fontWeight={"medium"}>
                      when
                    </Text>

                    {/* Dynamic conditions */}
                    {tag.tagRules[0].conditions.conditions.map((condition, index) => (
                      <Card width="full" p={3}>
                        <HStack key={index}>
                          {index === 0 ? (
                            <Box w="min" bg={`gray.100`} borderRadius={"md"} py={1} px={2}>
                              <AiOutlineQuestionCircle size={24} />
                            </Box>
                          ) : (
                            <Text w={200}>And</Text>
                          )}
                          <Select
                            placeholder="Property"
                            required
                            value={condition.property}
                            onChange={(e) =>
                              handleConditionChange(index, "property", e.target.value)
                            }
                            size={{ base: "sm", "2xl": "md" }}
                            fontSize={{ base: "md", "2xl": "lg" }}
                            color={`${theme}.800`}
                          >
                            {Object.keys(Property)
                              .sort()
                              .map((key: string) => (
                                <option value={Property[key as keyof typeof Property]}>
                                  {formatEnumValue(Property[key as keyof typeof Property])}
                                </option>
                              ))}
                          </Select>

                          <Select
                            required
                            value={condition.operation}
                            onChange={(e) =>
                              handleConditionChange(index, "operation", e.target.value)
                            }
                            size={{ base: "sm", "2xl": "md" }}
                            fontSize={{ base: "md", "2xl": "lg" }}
                            color={`${theme}.800`}
                          >
                            {/* Hardcoded operation options based on the Operation enum */}
                            <option value={Operation.LT}>Is Less Than</option>
                            <option value={Operation.LTE}>Is Less Than or Equal To</option>
                            <option value={Operation.EQ}>Is Equal To</option>
                            <option value={Operation.GTE}>
                              Is Greater Than or Equal To
                            </option>
                            <option value={Operation.GT}>Is Greater Than</option>
                          </Select>

                          <Input
                            required
                            placeholder="Value"
                            value={condition.value}
                            onChange={(e) =>
                              handleConditionChange(index, "value", e.target.value)
                            }
                            size={{ base: "sm", "2xl": "md" }}
                            fontSize={{ base: "md", "2xl": "lg" }}
                            color={`${theme}.800`}
                          />

                          <IconButton
                            colorScheme="gray"
                            onClick={() => removeCondition(index)}
                            icon={<FaTrash />}
                            aria-label={"delete"}
                            isDisabled={index === 0}
                          />
                        </HStack>
                      </Card>
                    ))}

                    <Button
                      onClick={addCondition}
                      leftIcon={<FaPlus />}
                      variant={"ghost"}
                      w={150}
                      color={`${theme}.700`}
                      size={{ base: "md", "2xl": "lg" }}
                    >
                      Add Condition
                    </Button>

                    <Checkbox
                      isChecked={tag.removeWhenNotMet}
                      name="removeWhenNotMet"
                      onChange={handleChange}
                      size={{ base: "md", "2xl": "lg" }}
                      color={`${theme}.700`}
                    >
                      Remove tag when conditions are no longer met
                    </Checkbox>

                    <Button onClick={handleSave} mt={4} type="submit" w="full" size={{ base: "md", "2xl": "lg" }}>
                      Save
                    </Button>
                  </VStack>
                </Flex>
              </TabPanel>

              <TabPanel>
                <Box mt={6}>
                  <PatientTagsTable patientTags={patientTags} />
                  <TablePagination
                    currentPage={currentPage}
                    totalRows={totalTags}
                    currentPageRows={patientTags.length}
                    onPageChange={setCurrentPage}
                    rowsPerPage={itemsPerPage}
                    onChangeRowsPerPage={setItemsPerPage}
                  />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Container>
    </>
  );
};
