import {
  Box,
  Card,
  CardBody,
  Container,
  HStack,
  Heading,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { rrulestr } from "rrule";
import { getWorkflowDataCount } from "../../api/workflowData";
import { getWorkflowList } from "../../api/workflows";
import Header from "../../components/Head";
import { ROUTES, theme } from "../../constants";
import { WorkflowCardData, WorkflowStatus, WorkflowTemplateId } from "../../types/workflow";
import { AiOutlineFileText } from "react-icons/ai";
import { workflowTemplates } from "../../constants/templates";
import { getReadableRRule } from "../../constants/time";
import { getStatusColor } from "../../utils";

const FlowPage = () => {
  const [flows, setFlows] = useState<WorkflowCardData[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getFlows() {
      // retrieve flows from backend
      const workflowDtos = await getWorkflowList();
      const cardDataDict: Map<string, WorkflowCardData> = new Map();
      workflowDtos.forEach((dto) => {
        cardDataDict.set(dto.id, {
          workflowId: dto.id,
          status: dto.status,
          title: dto.name,
          frequency: dto.schedule ? rrulestr(dto.schedule) : undefined,
          numRespondents: dto.patientIds.length,
          numResponses: 0,
          createdBy: dto.createdBy.name,
        });
      });

      // get the count of the responses for the workflow ids
      const responsesCountResp = await getWorkflowDataCount(
        workflowDtos.map((dto) => dto.id)
      );
      responsesCountResp?.forEach((wfCount) => {
        const workflowId = wfCount.workflowId;
        const cardData = cardDataDict.get(workflowId);

        if (cardData) {
          cardDataDict.set(workflowId, {
            ...cardData,
            numResponses: wfCount.count,
          });
        }
      });

      // set state
      setFlows(Array.from(cardDataDict.values()));
    }

    getFlows();
  }, []);

  const activeFlows = flows
    .filter((flow) => flow.status === WorkflowStatus.PUBLISHED)
    .reverse();
  const deprecatedFlows = flows
    .filter((flow) => flow.status === WorkflowStatus.DEPRECATED)
    .reverse();

  return (
    <>
      <Container maxW={"9xl"}>
        <Header description="Flows Page" />
        <VStack spacing={8} align={"left"}>
          <Heading fontSize={{ base: "3xl", "2xl": "4xl" }} textTransform="uppercase">Forms</Heading>
          <VStack align={"left"} bg={`${theme}.50`} spacing={4} py={4} px={8} borderRadius="md" width="full">
            <Text fontSize={{ base: "lg", "2xl": "xl" }} fontWeight={"normal"} color={`${theme}.800`}>
              Choose from existing templates
            </Text>
            <HStack spacing={6} overflowX="auto" pb={2}>
              <Card 
                minW={{ base: 64, "2xl": 72 }}
                minH={{ base: 40, "2xl": 48 }}
                variant={"outline"}
                borderColor={`${theme}.900`}
                onClick={() => navigate(
                  ROUTES.CREATE_FLOW, {
                    state: {
                      createWorkflowDto: workflowTemplates[WorkflowTemplateId.CUSTOM].workflowTemplateDto
                    }
                  }
                )}
              >
                <CardBody alignContent={"center"}>
                  <HStack justifyContent={"center"}>
                    <FaPlus size={16} />
                    <Text fontWeight="semibold" fontSize={{ base: "md", "2xl": "lg" }}>Create new form</Text>
                  </HStack>
                </CardBody>
              </Card>
              {Object.values(workflowTemplates).filter((template) => template.workflowTemplateDto.templateId !== WorkflowTemplateId.CUSTOM).map((template, index) => {
                return (
                  <Card
                    key={index}
                    minW={{ base: 64, "2xl": 72 }}
                    minH={{ base: 40, "2xl": 48 }}
                    onClick={() => navigate(
                      ROUTES.CREATE_FLOW, {
                        state: {
                          createWorkflowDto: template.workflowTemplateDto
                        }
                      }
                    )}
                  >
                    <CardBody alignContent={"center"}>
                      <VStack align={"left"}>
                        <Box w="min" border={"1px dashed"} borderColor={`gray.300`} borderRadius={"md"} p={1}>
                          <AiOutlineFileText size={32} color="gray" />
                        </Box>
                        <Text fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} fontWeight={"medium"}>
                          {template.workflowTemplateDto.name}
                        </Text>
                        <Text fontSize={{ base: "sm", "2xl": "md" }} color={`${theme}.700`}>
                          {template.workflowTemplateDto.description}
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                )
              })}
            </HStack>
          </VStack>
          {(!flows || flows.length === 0) && (
            <Stack
              w="full"
              h={`calc(100vh - ${200}px)`}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={3}
            >
              <Text fontSize={{ base: "lg", "2xl": "xl" }} fontWeight={"normal"} color={`${theme}.800`}>
                You currently don’t have any active flows, create one now!
              </Text>
            </Stack>
          )}
          {flows && (
            <VStack align={"left"} mt={4}>
              {activeFlows.length > 0 && (
                <Text mb={4}fontSize={{ base: "lg", "2xl": "xl" }} fontWeight={"normal"} color={`${theme}.800`}>
                  Active Forms
                  <Tag
                    ml={2}
                    variant="solid"
                    colorScheme={getStatusColor(WorkflowStatus.PUBLISHED)}
                    maxW={"fit-content"}
                    textTransform={"capitalize"}
                  >
                    {WorkflowStatus.PUBLISHED.toLowerCase()}
                  </Tag>
                </Text>
              )}
              <SimpleGrid minChildWidth={"md"} spacing={4}>
                {activeFlows?.map((flow) => {
                  return (
                    <Card
                      key={flow.workflowId}
                      minW={{ base: 64, "2xl": 72 }}
                      minH={{ base: 40, "2xl": 48 }}
                      onClick={() =>
                        navigate(
                          `${ROUTES.FLOWS_LIST}/${flow.workflowId}`
                        )
                      }
                      cursor={"pointer"}
                    >
                      <CardBody alignContent={"center"}>
                        <VStack align={"left"} spacing={1}>
                          <Box w="min" bg={`gray.100`} borderRadius={"md"} p={1} mb={2}>
                            <AiOutlineFileText size={32} />
                          </Box>
                          <Text mb={2} fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} fontWeight={"medium"}>
                            {flow.title}
                          </Text>
                          <Text fontSize={{ base: "sm", "2xl": "md" }} color={`${theme}.700`}>
                            Created by: {flow.createdBy || "Unknown staff"}
                          </Text>
                          {flow.frequency && (
                            <Text fontSize={{ base: "sm", "2xl": "md" }} color={`${theme}.700`}>
                              {getReadableRRule(flow.frequency.toString())}
                            </Text>
                          )}
                          <Text fontSize={{ base: "sm", "2xl": "md" }} color={`${theme}.700`}>
                            {flow.numResponses} Responses
                          </Text>
                        </VStack>
                      </CardBody>
                    </Card>
                  );
                })}
              </SimpleGrid>
            </VStack>
          )}
          {flows && (
            <VStack align={"left"} mt={4}>
              {deprecatedFlows.length > 0 && (
                <Text mb={4} fontSize={{ base: "lg", "2xl": "xl" }} fontWeight={"normal"} color={`${theme}.800`}>
                  Archived Forms
                  <Tag
                    ml={2}
                    variant="solid"
                    colorScheme={getStatusColor(WorkflowStatus.DEPRECATED)}
                    maxW={"fit-content"}
                    textTransform={"capitalize"}
                  >
                    {WorkflowStatus.DEPRECATED.toLowerCase()}
                  </Tag>
                </Text>
              )}
              <SimpleGrid minChildWidth={"md"} spacing={4}>
                {deprecatedFlows?.map((flow) => {
                  return (
                    <Card
                      key={flow.workflowId}
                      minW={{ base: 64, "2xl": 72 }}
                      minH={{ base: 40, "2xl": 48 }}
                      onClick={() =>
                        navigate(
                          `${ROUTES.FLOWS_LIST}/${flow.workflowId}`
                        )
                      }
                      cursor={"pointer"}
                    >
                      <CardBody alignContent={"center"}>
                        <VStack align={"left"} spacing={1}>
                          <Box w="min" bg={`gray.100`} borderRadius={"md"} p={1} mb={2}>
                            <AiOutlineFileText size={32} />
                          </Box>
                          <Text mb={2} fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.800`} fontWeight={"medium"}>
                            {flow.title}
                          </Text>
                          <Text fontSize={{ base: "sm", "2xl": "md" }} color={`${theme}.700`}>
                            Created by: {flow.createdBy || "Unknown staff"}
                          </Text>
                          {flow.frequency && (
                            <Text fontSize={{ base: "sm", "2xl": "md" }} color={`${theme}.700`}>
                              {getReadableRRule(flow.frequency.toString())}
                            </Text>
                          )}
                          <Text fontSize={{ base: "sm", "2xl": "md" }} color={`${theme}.700`}>
                            {flow.numResponses} Responses
                          </Text>
                        </VStack>
                      </CardBody>
                    </Card>
                  );
                })}
              </SimpleGrid>
            </VStack>
          )}
        </VStack>
      </Container>
    </>
  );
};

export default FlowPage;
