import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import { ProtectedRoute } from "./components/ProtectedRoute";
import ScrollToTop from "./components/ScrollToTop";
import { ROUTES } from "./constants";
import CreateFlowPage from "./pages/CreateFlowPage";
import FlowPage from "./pages/FlowPage";
import ViewFlowPage from "./pages/ViewFlowPage";
import ForgotPasswordPage from "./pages/ForgotPassword";
import FormPage from "./pages/Form";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import ResetPasswordPage from "./pages/PasswordResetPage";
import PatientResponseView from "./pages/PatientResponseView";
import Patients from "./pages/Patients";
import ViewPatientInfoPage from "./pages/ViewPatientInfo";
import ViewTagsPage from "./pages/ViewTagsPage";
import ReportPage from "./pages/ReportPage";
import { CreateTagPage } from "./pages/CreateTagPage";
import { ViewPatientTagsPage }from "./pages/ViewPatientTagsPage";

export const App = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate(ROUTES.CLIENTS);
    }
  }, [navigate]);

  return (
    <ScrollToTop>
      <Routes>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        {/* <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} /> */}
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
        <Route
          path={`${ROUTES.CLIENTS}/:patientId${ROUTES.FLOW}/:flowId/view`}
          element={<PatientResponseView />}
        />
        <Route
          path={`${ROUTES.FORM}/:workflowId/:patientId`}
          element={<FormPage />}
        />
        <Route element={<Layout />}>
          <Route
            path={ROUTES.CLIENTS}
            element={
              <ProtectedRoute>
                <Patients />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.FLOWS_LIST}
            element={
              <ProtectedRoute>
                <FlowPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.CREATE_FLOW}
            element={
              <ProtectedRoute>
                <CreateFlowPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.FLOWS_LIST}/:flowId`}
            element={
              <ProtectedRoute>
                <ViewFlowPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.CLIENTS}/:patientId`}
            element={
              <ProtectedRoute>
                <ViewPatientInfoPage />
              </ProtectedRoute>
            }
          />

          <Route
            path={`${ROUTES.CLIENTS}/:patientId${ROUTES.FLOW}/:flowId`}
            element={
              <ProtectedRoute>
                <PatientResponseView isAdminView />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.TAGS}`}
            element={
              <ProtectedRoute>
                <ViewTagsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.TAGS}/:tagId`}
            element={
              <ProtectedRoute>
                <ViewPatientTagsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTES.TAGS}/new`}
            element={
              <ProtectedRoute>
                <CreateTagPage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path={`${ROUTES.REPORT}/:docId`}
          element={
            <ProtectedRoute>
              <ReportPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </ScrollToTop>
  );
};
