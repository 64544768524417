import axios from "axios";
import {
  CountResp,
  WorkflowDataDto,
  WorkflowDataListReq,
  WorkflowDataResp,
} from "../types/workflow";
import axiosAuth from "./axiosAuth";

// Using React environment variable for API URL
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";

async function createWorkflowData(
  dto: WorkflowDataDto
): Promise<WorkflowDataDto> {
  const res = await axiosAuth.post<WorkflowDataDto>(
    `${API_URL}/formData`,
    dto
  );
  return res.data;
}

async function updateWorkflowData(
  dto: WorkflowDataDto
): Promise<WorkflowDataDto> {
  const res = await axiosAuth.put<WorkflowDataDto>(
    `${API_URL}/formData`,
    dto
  );
  return res.data;
}

async function getWorkflowDataList(
  req?: WorkflowDataListReq
): Promise<WorkflowDataResp[]> {
  const res = await axios.get<WorkflowDataResp[]>(
    `${API_URL}/formData/list`,
    {
      params: req,
    }
  );
  return res.data;
}

async function getWorkflowDataCount(
  workflowIds: string[]
): Promise<CountResp[] | null> {
  const res = await axiosAuth.get<CountResp[]>(
    `${API_URL}/formData/count`,
    {
      params: { workflowIds },
    }
  );
  return res.data;
}

async function deprecateWorkflowDataById(
  workflowId: string
): Promise<WorkflowDataDto | null> {
  const res = await axiosAuth.delete<WorkflowDataDto>(
    `${API_URL}/formData/${workflowId}`
  );
  return res.data;
}

export {
  createWorkflowData,
  deprecateWorkflowDataById,
  getWorkflowDataCount,
  getWorkflowDataList,
  updateWorkflowData,
};
