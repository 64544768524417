import { Flex, Image, Text } from "@chakra-ui/react";

export const Logo = () => {
  return (
    <Flex
      direction={"column"}
      w={"full"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Image
        height={"50px"}
        width={"50px"}
        src="https://i.imgur.com/ix1eHvS.png"
      />
      <Text mt={4} fontFamily="heading" fontSize={16} fontWeight="bold" textTransform="uppercase">
        Speedback
      </Text>
    </Flex>
  );
};
