import {
  Avatar,
  Box,
  BoxProps,
  CloseButton,
  Divider,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import {
  FaSignOutAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { ROUTES, theme } from "../../constants";
import { Logo } from "../../Logo";
import { useStaffStore } from "../../store/staffStore";
import NavItem from "./NavItem";
import { AiOutlineForm, AiOutlineTag, AiOutlineTeam } from "react-icons/ai";

interface LinkItemProps {
  name: string;
  path: string;
  icon: IconType;
}

const LinkItems: Array<LinkItemProps> = [
  { name: "Clients", path: ROUTES.CLIENTS, icon: AiOutlineTeam },
  { name: "Forms", path: ROUTES.FLOWS_LIST, icon: AiOutlineForm },
  { name: "Tags", path: ROUTES.TAGS, icon: AiOutlineTag },
];

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { currentStaff, logout } = useStaffStore();

  const handleSignOut = () => {
    logout();
  };

  return (
    <Box
      bg={useColorModeValue("#ffffff", "gray.900")}
      borderRight="2px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      display="flex"
      flexDirection="column"
      {...rest}
    >
      <Flex
        flexDirection="column"
        flex="1" // Flex grow to take available space
      >
        <Flex h="20" alignItems="center" mx={8} my={12} justifyContent="space-between">
          <Logo />
          <CloseButton
            display={{ base: "flex", md: "none" }}
            onClick={onClose}
          />
        </Flex>
        <Box mx={8}>
          <Divider borderColor="gray.500" mb={4} />
        </Box>
        {LinkItems.map((link) => (
          <Link to={link.path} key={link.name} onClick={onClose}>
            <NavItem icon={link.icon}>{link.name}</NavItem>
          </Link>
        ))}
      </Flex>

      <Box p={4} my={4}>
        <Text
          fontSize="xs"
          fontWeight="semibold"
          fontFamily="heading"
          color={`${theme}.800`}
          mb={2}
          textTransform="uppercase"
        >
          {currentStaff?.organizationName || ""}
        </Text>
        <Flex alignItems="center">
          <Avatar name={currentStaff?.name || ""} size="sm" mr={2} />
          <VStack alignItems="flex-start" spacing={0}>
            <Text fontWeight="semibold" fontFamily="heading">
              {currentStaff?.name || "Loading..."}
            </Text>
            <Text fontSize="xs" fontFamily="heading" color="gray.500">
              {currentStaff?.email || ""}
            </Text>
          </VStack>
          <IconButton
            ml="auto"
            onClick={handleSignOut}
            icon={<FaSignOutAlt />}
            color="gray.800"
            variant="ghost"
            aria-label="Sign Out"
            size="lg"
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default SidebarContent;
