import {
  Box,
  Card,
  CardBody,
  Container,
  Divider,
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllTags } from "../../api/tag";
import Header from "../../components/Head";
import { ROUTES, theme } from "../../constants";
import { TagDto } from "../../types/tag";
import { AiOutlineTag } from "react-icons/ai";
import { TagPill } from "../../components/Tags/TagPill";
import { FaPlus } from "react-icons/fa";

export default function ViewTagsPage() {
  const navigate = useNavigate();

  const [tags, setTags] = useState<TagDto[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalTags, setTotalTags] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    fetchAllTags();
  }, [currentPage, itemsPerPage]);

  const fetchAllTags = async () => {
    const resp = await getAllTags(currentPage, itemsPerPage);
    if (resp.data) {
      setTags(resp.data.data);
      setTotalTags(resp.data.total);
    }
  };

  return (
    <>
      <Header description="Manage tags" />
      <Container maxW={"9xl"}>
        <VStack w="full" align={"flex-start"} spacing={4}>
          <Heading fontSize={{ base: "3xl", "2xl": "4xl" }} textTransform="uppercase">Tags</Heading>
          <Text 
            maxW="80%"
            fontSize={{ base: "lg", "2xl": "xl" }}
            fontWeight="normal"
            color={`${theme}.700`}
          >
            Surface the most important client information through tags.
            Define logic that will determine when Speedback automatically
            applies tags to clients and removes them.
          </Text>

          <Divider />

          <SimpleGrid
            spacing={4}
            w="full"
            columns={{ base: 1, sm: 2, md: 3, lg: 4, 'xl': 5 }}
            justifyContent={"start"}
            p={1}
          >
            <Card
              key={"create"}
              onClick={() => navigate(`${ROUTES.TAGS}/new`)}
              cursor={"pointer"}
              variant={"outline"}
              borderColor={`${theme}.900`}
            >
              <CardBody alignContent={"center"}>
                <HStack justifyContent={"center"}>
                  <FaPlus size={16} />
                  <Text fontWeight="semibold" fontSize={{ base: "md", "2xl": "lg" }}>Create Tag</Text>
                </HStack>
              </CardBody>
            </Card>
            {tags.map((tag, index) => {
              return (
                <Card
                  key={index}
                  onClick={() =>
                    navigate(`${ROUTES.TAGS}/${tag.id!}`)
                  }
                  cursor={"pointer"}
                >
                  <CardBody alignContent={"center"}>
                    <VStack h={"full"} align={"left"} spacing={2}>
                      <Box w="min" bg={`gray.100`} borderRadius={"md"} p={1} mb={2}>
                        <AiOutlineTag size={32} />
                      </Box>
                      <TagPill isTruncated tagColor={tag.tagColor} name={tag.name} w={"fit-content"} />
                      <Text 
                        noOfLines={2} 
                        mb={2} 
                        fontSize={{ base: "md", "2xl": "lg" }} 
                        color={`${theme}.800`} 
                        fontWeight={"medium"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                      >
                        {tag.description}
                      </Text>
                      <Spacer />
                      {tag.createdBy && (
                        <Text isTruncated fontSize={{ base: "xs", "2xl": "sm" }} color={`${theme}.700`}>
                          Created by {tag.createdBy}
                        </Text>
                      )}
                    </VStack>
                  </CardBody>
                </Card>
              );
            })}
          </SimpleGrid>
        </VStack>
      </Container>
    </>
  );
}
