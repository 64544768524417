import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate, useParams } from "react-router-dom";
import { getPatientById } from "../../api/patients";
import { getWorkflowById, respondToWorkflow } from "../../api/workflows";
import FormField from "../../components/Form/FormField";
import Header from "../../components/Head";
import { ROUTES } from "../../constants";
import { PatientDto } from "../../types/patient";
import { WorkflowDto } from "../../types/workflow";
import { checkFormValid } from "../../utils/validators";

const FormPage: React.FC = () => {
  const toast = useToast();
  const [patient, setPatient] = useState<PatientDto>();
  const [workflow, setWorkflow] = useState<WorkflowDto>();

  const { patientId, workflowId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (patientId) {
        fetchPatientById(patientId);
      }
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to fetch patient details, please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [patientId, toast]);

  useEffect(() => {
    try {
      if (workflowId) {
        fetchWorkflowById(workflowId);
      }
    } catch (error) {
      toast({
        title: "An error occurred.",
        description:
          "Unable to fetch workflow details, please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [workflowId, toast]);

  const fetchWorkflowById = async (id: string) => {
    const resp = await getWorkflowById(id);
    setWorkflow(resp);
  };

  const fetchPatientById = async (id: string) => {
    const resp = await getPatientById(id);
    if (resp.data) {
      setPatient(resp.data);
    }
  };

  const generateInitialState = () => {
    const initialState: { [key: string]: string } = {};
    workflow?.fields.forEach((field, idx) => {
      initialState[`q_${idx + 1}`] = "";
    });
    return initialState;
  };

  const [formValues, setFormValues] = useState(generateInitialState()); // Initialize formValues with the generated initial state
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (name: string, value: string) => {
    setFormValues({ ...formValues, [name]: value });

    // Track field interaction
    ReactGA.event({
      category: "Form",
      action: "Field Interacted",
      label: `${workflowId} - ${name}`,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!patient || !workflow) {
      return;
    }

    const { success, errorMsg } = checkFormValid(workflow, formValues);
    if (!success) {
      toast({
        title: "Invalid form.",
        description: errorMsg,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      if (!patientId || !workflowId) {
        throw new Error("Patient ID or Workflow ID is missing.");
      }

      respondToWorkflow({
        patientId: patientId,
        workflowId: workflowId,
        rawData: formValues,
        sendAcknowledge: true,
      });
      ReactGA.event({
        category: "Form",
        action: "Submit",
        label: "Form Submission",
      });

      setIsSubmitted(true);
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to submit form, please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (!patient || !workflow) {
    return <Center h="90vh">Loading...</Center>;
  }

  if (isSubmitted) {
    return (
      <Center h="90vh">
        <Box textAlign="center" p="4">
          <Heading mb="4">{workflow.postSubmitMsg?.header ?? ""}</Heading>
          <Text>
            {workflow.postSubmitMsg?.body ?? "Thank you for submitting!"}
          </Text>
          <Button
            mt={8}
            onClick={() => {
              ReactGA.event({
                category: "Form",
                action: "Post Submit",
                label: "View Data",
              });
              navigate(
                `${ROUTES.CLIENTS}/${patient.id}${ROUTES.FLOW}/${workflow.id}/view`
              );
            }}
          >
            View Data
          </Button>
        </Box>
      </Center>
    );
  }

  return (
    <>
      <Header description="Complete your forms here" />

      <Container maxW="md" p={8}>
        <Heading mb={1}>Hey {patient?.name},</Heading>
        <Text size="sm" mb={6}>
          {workflow.message.replace("${name}", patient.name)}
        </Text>
        <Box as="form" onSubmit={handleSubmit}>
          {workflow.fields.map((field, index) => {
            return (
              <FormField
                key={index}
                questionNum={index + 1}
                field={field}
                handleInputChange={handleInputChange}
                mb={8}
              />
            );
          })}
          <Button mt="4" type="submit">
            Submit
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default FormPage;
